import React from 'react';
import { Input as BaseInput, SIZE } from 'baseui/input';

const getInputFontStyle = ({ $theme }) => {
  return {
    color: $theme.colors.textDark,
    backgroundColor:"#343b40",
    caretColor:"rgb(207, 209, 215)",
    ...$theme.typography.fontBold14,
  };
};

const Input = ({ ...props }) => {
  return (
    <BaseInput
      overrides={{
        Input: {
          style: ({ $theme  }) => {
            return {
              ...getInputFontStyle({ $theme }),
            };
          },
        },
        Root:{style:({$theme , $isFocused}) => ({
          borderColor: $isFocused ? "rgb(47 57 63)" : "#343b40",
          backgroundColor:"#343b40",

        })},

        InputContainer: {
          style: ({ $theme, $isFocused }) => ({
            backgroundColor:  "#343b40",
          }),
        },

        ClearIcon: {
          style: ({ $theme }) => ({
            backgroundColor:"#343b40",
            color: "rgb(207, 209, 215)"
          }),
        },
        EndEnhancer: {
          style: ({ $theme }) => ({
            backgroundColor:"#343b40",
            color:"rgb(207, 209, 215)"
          }),
        },
        
      }}
      {...props}
    />
  );
};

export { SIZE };
export default Input;
