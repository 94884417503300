

import React, { useState } from 'react';
import { styled, withStyle,createThemedUseStyletron } from 'baseui';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import Input from '../../components/Input/Input';
import { Wrapper, Header, Heading } from '../../components/WrapperStyle';

import {Button, KIND} from 'baseui/button';
import {Filter, Menu, TriangleDown} from 'baseui/icon';
import { injectIntl } from 'react-intl';


import { FormattedMessage } from 'react-intl';


import { useLocale } from '../../context/language/language.provider';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import Select from '../../components/Select/Select';
require('dayjs/locale/ar');
require('dayjs/locale/fr');
require('dayjs/locale/en');


type CustomThemeT = { red400: string; textNormal: string; colors: any };
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>();

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const Status = styled('div', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1',
  textTransform: 'capitalize',

  ':before': {
    content: '""',
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: $theme.borders.borderE6,
    marginRight: '10px',
  },
}));

const Image = styled('img', () => ({
  width: '100%',
  height: 'auto',
}));

const ImageWrapper = styled('div', ({ $theme }) => ({
  width: '38px',
  height: '38px',
  overflow: 'hidden',
  display: 'inline-block',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  borderBottomLeftRadius: '20px',
  backgroundColor: $theme.colors.backgroundF7,
}));





const Messenger = ({intl}) => {

  const statusSelectOptions = [
    { value: 'waiting', label: intl.formatMessage({id:'Waiting'})},
    { value: 'in progress', label: intl.formatMessage({id:'InProgress'}) },
    { value: 'closed', label: intl.formatMessage({id:'Treated'})},
    { value: 'suspended', label: intl.formatMessage({id:'Canceled'}) },
  ];
  
  

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const {locale} = useLocale();
  // sortable header
  const [state,setState] = React.useState( {refDirection : null  , dateDirection : null , userDirection : null ,statusDirection : null , serviceDirection : null });

  const [ref,setRef] = useState(null);

  const [view , setView] = React.useState('card');

  const [status, setStatus] = useState([]);

  // function for sortable header 

 


  const [useCss, theme] = themedUseStyletron();
  const completed = useCss({
    ':before': {
      content: '""',
      backgroundColor: theme.colors.primary,
    },
  });
  const canceled = useCss({
    ':before': {
      content: '""',
      backgroundColor: theme.colors.red400,
    },
  });
  const inProgress = useCss({
    ':before': {
      content: '""',
      backgroundColor: theme.colors.blue400,
    },
  });








  function handleSelectStatus({ value }) {
    setStatus(value);
    if (value.length) {
      setRef(value[0].value);
    }
    else 
    {
      setRef('');
    }
  }
    
    const [search, setSearch] = useState([]);


    const [timer, setTimer] = useState(null)


    function handleSearch(event) {
      const value = event.currentTarget.value;
  
      setSearch(value);
  
      clearTimeout(timer)
  
      
      const newTimer = setTimeout(() => {
        
        setRef(value);
        setPage(1);
          }, 500)
  
          setTimer(newTimer)
  
    }


    const directLink = "https://m.me/swell.bot"; 

  

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 5px rgba(0, 0 ,0, 0.05)',
            }}
          >
            <Col md={2}>
              <Heading><FormattedMessage id = "Tickets"/></Heading>
            </Col>
            <Col md={2}>
                  <Select
                    options={statusSelectOptions}
                    labelKey="label"
                    valueKey="value"
                    placeholder={intl.formatMessage({id:"Status"})}
                    value={status}
                    searchable={false}
                    onChange={handleSelectStatus}
                  />
                </Col>
            <Col md={6}>

                  <Input
                    value={search}
                    placeholder={intl.formatMessage({id : "ExSearchByReference"})}
                    onChange={handleSearch}
                    clearable
                  />
                </Col>

               
            <Col md = {2}>
                        <StatefulButtonGroup
                  mode={MODE.radio}
                  initialState={{selected: 0}}
                >
                      <Button onClick={()=> setView('card')}><Menu/></Button>
                      <Button onClick = {() => setView ('table')}><Filter/></Button>
                </StatefulButtonGroup>       
            </Col>
          </Header>

       


          <div>
      <iframe
        title="Messenger Admin"
        src={directLink}
        width="100%"
        height="600px"
        X-Frame-Options= "DENY"

      ></iframe>
    </div>

            

        </Col>
      </Row>
    </Grid>
  );
}
export default injectIntl(Messenger);
