import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import { SWRConfig } from "swr";
/* react-notification import  */
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import  {ReactNotifications}  from 'react-notifications-component'
import Notifications from './Notifications';

// import {io} from "socket.io-client";

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_API_URL,
// });
 
import { messages } from './settings/site-translation/messages';

import { LanguageProvider } from './context/language/language.provider';



function App() {
  const engine = new Styletron();
  const MessengerRef = useRef();

  
  if ('serviceWorker' in navigator) {

    navigator.serviceWorker.register('./background-worker.js')
      .then((registration) => {
        console.log('Background Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Background Service Worker registration failed:', error);
      });
  }




  useEffect(() => {

    MessengerRef.current.setAttribute("page_id", "103120191131697");
    MessengerRef.current.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function () {
        FB.init({
            xfbml: true,
            version: 'v15.0'
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}, [])



//   const [show, setShow] = useState(false);
//   const [notification, setNotification]=useState({title:"",body:""});

//   onMessageListener()
//   .then((payload) => {
//      setNotification({
//        title: payload.notification.title,
//        body: payload.notification.body,
//      });
//   })
// .catch((err) => console.log("failed: ", err));
  //  const ENDPOINT = "http://localhost:5000"
  // React.useEffect(()=> {
  //   const socket = io(ENDPOINT) ; 
  //   socket.on("connect" , () => {console.log(socket.id)}); 
  //   socket.emit('chat message', 'bonjour tout le monde');
  //   return () => socket.disconnect();
  // },[])
  return (
    <SWRConfig value={{refreshInterval : 10000 }}>
    {/* <ApolloProvider client={client as any}> */}




    <LanguageProvider messages={messages}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>

          <BrowserRouter>
          <HashRouter>

          <ReactNotifications/>
          {/* <Notifications/> */}
            <Routes />
            </HashRouter>
          </BrowserRouter>
          <div id="fb-root" style={{ display:"block" }} ></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
        </div>

        </BaseProvider>
      </StyletronProvider>
      </LanguageProvider>
    {/* </ApolloProvider> */}


    </SWRConfig>

  );
}

ReactDOM.render(<App />, document.getElementById('my_react_app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
