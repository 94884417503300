import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  SIGNUP,
  FORGETPASSWORD,
  PROFILE,
  ALL_TRANSACTIONS,
  MY_TRANSACTIONS,
  DASHBOARD,
  DASHBOARD_ADMIN , 
  ALL_ORDERS,
  MY_ORDERS,
  ORDER ,
  ALL_TICKETS,
  TICKET_ADMIN ,
  TICKET_CLIENT , 
  MY_TICKETS, 
  SETTINGS_ADMIN,
  SETTINGS , 
  CUSTOMERS,
  STAFF_MEMBERS,
  MY_FILES,
  ALL_FILES,
  FILE,
  ALL_VEHICLES , 
  VEHICLE ,
  TUNING , 
  TUNING_FRAME,
  CHECKOUT ,
  CREDITS , 
  SINGLE_CREDIT,
  MAILING,
  CHANGE_PASSWORD,
  EDIT_USER,
  NOTIFICATIONS,
  HELP,
  TRAINING,
  ECU_NUMBERS,
  DTC_CODES,
  DTC_CODES_MANUAL,
  ECU_NUMBERS_MANUAL,
  DOWNLOAD,
  DIAGRAM_WIRING,
  PRICE_LIST_FRAME,
  PRICE_LIST,
  RESET_PASSWORD,
  ECU_LOCATION,
  DF_TO_PCODE,
  OBD_LOCATION,
  PINOUT,
  TUTORIALS,
  FILE_SECTION,
  TICKET_SECTION,
  ORDER_SECTION,
  OTHERS_SECTION,
  POPUP_EDIT,
  POPUP_ADD,
  TUNING_FRAME_TPREMAPPING,
  TUNING_FRAME_TUNEMYRIDE,
  CREATE_MAIL,
  EDIT_MAIL,
  SOLUTION_CATEGORY,
  SOLUTION_COMBINATION,
  MESSENGER
} from './settings/constants';
import AuthProvider, { AuthContext } from './context/auth';

import { InLineLoader } from './components/InlineLoader/InlineLoader';
import { Price } from './components/DashboardCard/StickerCard.style';
import EcuLocation from './containers/Help/EcuLocation';
import DfToPcode from './containers/Help/DfToPcode';
import ObdLocation from './containers/Help/ObdLocation';
import Pinout from './containers/Help/Pinout';
import FileSection from './containers/Help/FileSection';
import TicketSection from './containers/Help/TicketSection';
import OrderSection from './containers/Help/OrderSection';
import Others from './containers/Help/Others';
import TuningFrameTuneMyRide from './containers/Vehicles/TuningFrameTuneMyRide';

import Messenger from './containers/tickets/messenger';

// user managment component 
const Profile = lazy(() => import('./containers/UserProfile/Profile'));
const EditUser = lazy(() => import('./containers/Customers/EditUser'));
const StaffMembers = lazy(() =>
  import('./containers/StaffMembers/StaffMembers')
);
const Customers = lazy(() => import('./containers/Customers/Customers'));
const Login = lazy(() => import('./containers/Login/Login'));
const ChangePassword = lazy(() => import('./containers/Login/ChangePassword'));

const Signup = lazy(() => import('./containers/Login/Signup'));
const ForgetPassword = lazy(() => import('./containers/Login/ForgetPassword'));
const ResetPassword = lazy(() => import('./containers/Login/ResetPassword'));

// files component
const MyFiles = lazy(() => import('./containers/Files/MyFiles'));
const AllFiles = lazy(() => import('./containers/Files/FilesAdmin'));
const SingleFile = lazy(() => import('./containers/Files/SingleFile'));

// transactions component
const AllTransactions = lazy(() => import('./containers/Transactions/AllTransactions'));
const MyTransactions = lazy (() => import ('./containers/Transactions/MyTransactions'));

//orders component
const AllOrders = lazy(() => import('./containers/Orders/AllOrders'));
const MyOrders = lazy(() => import('./containers/Orders/MyOrders'));
const SingleOrder = lazy(() => import('./containers/Orders/SingleOrder'));
const Checkout = lazy(() => import('./containers/Orders/Checkout'));

//tickets component
const AllTickets = lazy(() => import('./containers/tickets/AllTickets'));
const MyTickets = lazy(() => import('./containers/tickets/MyTickets'));
const SingleTicketAdmin = lazy(() => import('./containers/tickets/SingleTicketAdmin'));
const SingleTicketClient = lazy(() => import('./containers/tickets/SingleTicketClient'));

// vehicles component 
const AllVehicles = lazy(() => import('./containers/Vehicles/AllVehicles'))
const SingleVehicle = lazy(() => import('./containers/Vehicles/SingleVehicle'))
const Tuning = lazy(() => import('./containers/Vehicles/Tuning'))
const TuningFrameTpRemapping = lazy(() => import('./containers/Vehicles/TuningFrameTpRemapping'))

// settings
const Settings = lazy(() => import('./containers/Settings/Settings'));
const SettingsAdmin = lazy(() => import('./containers/Settings/SettingsAdmin'));
const Mailing = lazy(() => import('./containers/Mailing/AllMails'));

// credit management 
const AllCredits = lazy(() => import('./containers/Credits/AllCredits')) ; 
const SingleCreditPack = lazy(() => import('./containers/Credits/SingleCreditPack')) ; 

//solutionManagement 
const Category = lazy(() => import('./containers/Credits/CategorySolution')) ; 
const CombinationSolution = lazy(() => import('./containers/Credits/SingleCombinationSolution')) ; 


//help 
const Help = lazy(() => import('./containers/Help'));
const DtcManual = lazy(() => import('./containers/Help/DtcManual'));
const DtcCodes = lazy(() => import('./containers/Help/DtcCodes'));
const Download = lazy(() => import('./containers/Help/Download'));

const DiagramWiring = lazy(() => import('./containers/Help/DiagramWiring'));
const Tutorials = lazy(() => import('./containers/Help/Tutorials'));


// training 
const Training = lazy(() => import('./containers/Training'));

// ecu numbers 
const EcuNumbers = lazy(() => import('./containers/Help/EcuNumbers'));
const EcuNumbersManual = lazy(() => import('./containers/Help/EcuNumbersManual'));

const PopupEdit = lazy(() => import('./containers/Popup/EditPopup'));
const PopupAdd = lazy(() => import('./containers/Popup/AddPopup'));


const MailEdit = lazy(() => import('./containers/Mailing/EditMail'));
const MailCreate = lazy(() => import('./containers/Mailing/CreateMail'));


//price list
const PriceList = lazy(() => import('./containers/priceList/TuningSolutionForm'));


const Notifications = lazy(() => import('./containers/Notifications/Notifications')) ; 



const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const DashboardAdmin = lazy(() => import('./containers/Dashboard/DashboardAdmin'));

const NotFound = lazy(() => import('./containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children,authority, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  const {user} = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          authority.includes(user.role) ?
          (children) : 
          <Route component={NotFound} />

        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
       
      <Suspense fallback={<InLineLoader />}>
        <Switch>
        <PrivateRoute authority={['admin','technician']} exact={true} path={DASHBOARD_ADMIN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DashboardAdmin />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={PROFILE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Profile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={MY_TRANSACTIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MyTransactions />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={ALL_TRANSACTIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllTransactions />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={MY_ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MyOrders/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={ALL_ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllOrders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={ORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleOrder />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={CHECKOUT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Checkout />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={SETTINGS_ADMIN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SettingsAdmin />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
     
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={FILE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleFile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={EDIT_USER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EditUser />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={MY_FILES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MyFiles />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={ALL_FILES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllFiles />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={ALL_TICKETS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllTickets/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={TICKET_ADMIN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleTicketAdmin />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={TICKET_CLIENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleTicketClient />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={MY_TICKETS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MyTickets />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={ALL_VEHICLES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllVehicles />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={VEHICLE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleVehicle />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={CREDITS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllCredits />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','technician']} exact={true} path={SINGLE_CREDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SingleCreditPack />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={TUNING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Tuning />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={NOTIFICATIONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Notifications />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={TRAINING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Training />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={HELP}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Help />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DTC_CODES_MANUAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DtcManual />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DTC_CODES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DtcCodes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={ECU_NUMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EcuNumbers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={ECU_NUMBERS_MANUAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EcuNumbersManual />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DOWNLOAD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Download />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={ECU_LOCATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EcuLocation />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DF_TO_PCODE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DfToPcode />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={OBD_LOCATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ObdLocation />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={PINOUT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Pinout />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={DIAGRAM_WIRING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DiagramWiring />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={PRICE_LIST}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceList />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={TUTORIALS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Tutorials />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={FILE_SECTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FileSection />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={TICKET_SECTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TicketSection />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={ORDER_SECTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderSection />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={OTHERS_SECTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Others />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={POPUP_EDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PopupEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={POPUP_ADD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PopupAdd />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={CREATE_MAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MailCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={EDIT_MAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MailEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={SOLUTION_CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>


          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={SOLUTION_COMBINATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CombinationSolution />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>


          <PrivateRoute authority={['admin','user','technician','decrypt-user']} exact={true} path={MESSENGER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Messenger />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>


          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={CHANGE_PASSWORD}>
            <ChangePassword/>
          </Route>
          <Route path={RESET_PASSWORD}>
            <ResetPassword/>
          </Route>
          <Route path={SIGNUP}>
            <Signup />
          </Route>
          <Route path={FORGETPASSWORD}>
            <ForgetPassword />
          </Route>
          <Route path={TUNING_FRAME}>
            <Tuning />
          </Route>
          <Route path={TUNING_FRAME_TPREMAPPING}>
            <TuningFrameTpRemapping />
          </Route>
          <Route path={TUNING_FRAME_TUNEMYRIDE}>
            <TuningFrameTuneMyRide />
          </Route>
          <Route path={PRICE_LIST_FRAME}>
            <PriceList />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>



    </AuthProvider>
  );
};

export default Routes;
