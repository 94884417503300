import * as React from 'react';
import { Select } from 'baseui/select';
import { CaretDownIcon } from '../../assets/icons/CaretDownIcon';

export const getContainerFontStyle = ({ $theme }) => {
  return $theme.typography.fontBold14;
};

export default ({ ...props }) => {
  return (
    <Select
      overrides={{
        SelectArrow: () => {
          return <CaretDownIcon />;
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: { zIndex: 1 },
              },
            },
          },
        },
        Placeholder: {
          style: ({ $theme }) => ({
            color: $theme.colors.textDark,
            backgroundColor: "#343b40",
            ...$theme.typography.fontBold14,

          }),
        },
        SingleValue: {
          style: ({ $theme }) => ({
            color: $theme.colors.textDark,
            backgroundColor: "#343b40",
            lineHeight: '1.5',
            ...$theme.typography.fontBold14,

          }),
        },
        DropdownListItem: {
          style: ({ $theme, $selected, $isHighlighted }) => ({
            ...$theme.typography.fontBold14,

            fontSize: '14px',
            fontWeight: '700',
            color: $theme.colors.textDark,
            backgroundColor: $selected
            ? "rgb(47 57 63)"
            : $isHighlighted
            ? "rgb(47 57 63)"
            :"#343b40"
  ,
        
          }),
        },
        OptionContent: {
          style: ({ $theme, $selected }) => {
            return {
              ...$theme.typography.fontBold14,

              color: $selected
                ? $theme.colors.textNormal
                : $theme.colors.textDark,
            };
          },
        },

        ControlContainer: {
          style: ({ $theme , $isFocused }) => ({
            backgroundColor: "#343b40",
            borderColor: $isFocused ? "rgb(47 57 63)" : "#343b40",
            ...$theme.typography.fontBold14,
            caretColor:"rgb(207, 209, 215)",
            color: $theme.colors.textDark,

          })
        },

        DropdownOption: {
          style: ({ $theme ,}) => ({
            fontSize: '14px',
            fontWeight: '700',
            color: $theme.colors.textDark,
            ...$theme.typography.fontBold14,


          }),
        },
        Dropdown : {
          style: ({ $theme ,}) => ({
            backgroundColor: "#343b40",
            ...$theme.typography.fontBold14,
            color: $theme.colors.textDark,

          })
        },
        DropdownContainer : {
          style: ({ $theme ,}) => ({
            backgroundColor: "#343b40",
            ...$theme.typography.fontBold14,
            color: $theme.colors.textDark,

          })
        },
        Input : {
          style: ({ $theme ,}) => ({
            color: $theme.colors.textDark,

          })
        }
      }}
      {...props}
    />
  );
};
