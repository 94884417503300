import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiUsers, ApiFiles, ApiDownload, ApiNotifications, ApiOrders, ApiTickets, ApiTransactions, ApiUpload, ApiVehicles, ApiDashboard, ApiCredits, ApiDtc, ApiWarningMessages, ApiEcuNumbers, ApiSettings, ApiCouponCodes, ApiEcuNotes, ApiPopup, ApiMails, ApiFilesToDownload, ApiDfCodes, ApiSolutionsFormula, ApiSolutions, ApiSolutionCategorys } from '../services';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  user: {
    authorization: string,
    role: string,
    settings: {},
    userInfo:
    {
      email: string,
      name: string,
      surname: string,
      photo: string,
      masterCredit: number,
      slaveCredit: number
    }
  };
  saveUser: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

// const isValidToken = () => {

//   var b: boolean = false ;
//   const token = localStorage.getItem('authorization');

//   // const  logged =  ()  : Promise<any> =>{
//   const  logged =  ()  : Promise<any> =>{
//       return   ApiUsers.post('/logged');
//       // return res.data.status ; 
//   }

//     if (token)
//     {
//       ApiUsers.post('/logged').then( (res) => {
//        if (res.data.status === 'success') 
//        b = true 
//        return true

//     })
//   }else {
//     return false

//   }


// };


const setAxiosHeadears = (token) => {
  const Apis = [ApiSolutionCategorys, ApiSolutions, ApiSolutionsFormula, ApiUsers, ApiFiles, ApiDownload, ApiNotifications, ApiOrders, ApiTickets, ApiTransactions, ApiUpload, ApiVehicles, ApiDashboard, ApiCredits, ApiDtc, ApiDfCodes, ApiWarningMessages, ApiEcuNumbers, ApiSettings, ApiCouponCodes, ApiEcuNotes, ApiPopup, ApiMails, ApiFilesToDownload]

  Apis.forEach(api => {

    api.interceptors.request.use(function (config) {
      config.headers = { 'authorization': token };
      return config;
    });

  });



}

const AuthProvider = (props: any) => {
  // const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [isAuthenticated, makeAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState(
    {
      authorization: '',
      role: '',
      settings: {},
      userInfo:
      {
        email: '',
        name: '',
        surname: '',
        photo: '',
        masterCredit: 0,
        slaveCredit: 0
      }
    }
  );
  const history: any = useHistory();
  const saveUser = user => {
    const newUser = {
      authorization: user.data.token,
      role: user.data.data.user.role,
      settings: user.data.data.settings,
      userInfo:
      {
        email: user.data.data.user.email,
        name: user.data.data.user.name,
        surname: user.data.data.user.surname,
        photo: user.data.data.user.photo,
        masterCredit: user.data.data.user.masterCredit,
        slaveCredit: user.data.data.user.slaveCredit
      }
    };
    setUser(newUser);
  }


  useEffect(() => {
    const token = localStorage.getItem('authorization'); // get token 
    if (token) {
      //check token is valid or not
      ApiUsers.post('/logged', null, { headers: { authorization: token } }).then(async (res) => {
        // set is authentifated and redirect to prev route
        makeAuthenticated(true);
        saveUser(res);
        setAxiosHeadears(res.data.data.token);
        if (history.location.state)
          history.push(history.location.state.from.pathname)

      }
      ).catch(() => makeAuthenticated(false))

    }

  }, [window.location])


  async function authenticate({ email, password, setTestLogin, tokenFirebaseDesktop }) {

    try {
      const res = await ApiUsers.post('/login', { email, password, tokenFirebaseDesktop });
      localStorage.setItem('authorization', res.data.token);
      makeAuthenticated(true);
      saveUser(res);
      setAxiosHeadears(res.data.token);
      // if (res.data.data.user.changePassword)
      // history.push(CHANGE_PASSWORD);
      history.push('/');
      window.location.reload();

    }
    catch (e) {
      setTestLogin(true);
    }
  }


  async function signout(cb) {
    makeAuthenticated(false);
    const res = await ApiUsers.get('/logout');
    localStorage.removeItem("authorization");
    setTimeout(cb, 100);
  }


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        user,
        saveUser
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
